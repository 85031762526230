import Axios from "axios";
/*export const baseURL = `https://sandbox.heizkosten-plattform.de/api/v2/tenant`;*/
/*export const baseURL = `https://portal.heizkosten-plattform.de/api/v2/tenant`;*/
export const baseURL = `https://heizkostenplattform.kreisbau.de/api/v2/tenant`;

export const apiClient = Axios.create({
  baseURL,
});

// Alter defaults after instance has been created
export const setApiKey = (apiKey) => {
  apiClient.defaults.params = apiClient.defaults.params || {};
  if (!apiKey) {
    delete apiClient.defaults.params["auth_token"];
    localStorage.removeItem("authToken");
  } else {
    apiClient.defaults.params["auth_token"] = apiKey;
    localStorage.setItem("authToken", apiKey);
  }
};

// setApiKey(localStorage.getItem('authToken'));
